<template>
  <v-footer dark padless>
    <v-layout row>
      <v-flex xs12 dark>
        <v-card flat tile class="primary text-center">
          <v-card-text>
            <v-btn
              v-for="ico in icons"
              :key="ico.ref"
              class="mx-4 white--text"
              :href="ico.ref"
              target="_blank"
              icon
            >
              <v-icon size="24px">
                {{ ico.icon }}
              </v-icon>
            </v-btn>
          </v-card-text>

          <v-card-text class="white--text pt-0"
            >You Shop, We Deliver
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="white--text">
            {{ new Date().getFullYear() }} —
            <strong>TUKTUK ONLINE STORE</strong>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-footer>
</template>
<script>
export default {
  name: "Footer",
  data: () => ({
    icons: {
      facebook: {
        icon: "mdi-facebook",
        ref: "https://www.facebook.com/TukTuk-328381271822067/"
      },
      twitter: {
        icon: "mdi-twitter",
        ref: "https://www.twitter.com/TukTukZW"
      },
      whatsapp: {
        icon: "mdi-whatsapp",
        ref: "https://wa.me/+263713198459"
      }
    }
  })
};
</script>
