<template>
  <v-layout app>
    <v-system-bar color="primary" class="" dark app> </v-system-bar>
    <v-app-bar height="100" light app>
      <v-row justify="center">
        <v-col cols="3">
          <v-row justify="center">
            <v-toolbar-title>
              <v-img
                alt="TukTuk"
                class="shrink hidden-md-and-up mt-7"
                contain
                src="~@/assets/logo4.png"
                width="100"
              />
              <v-img
                alt="TukTuk"
                class="shrink hidden-sm-and-down mt-4"
                contain
                src="~@/assets/logo4.png"
                transition="scale-transition"
                width="140"
              />
            </v-toolbar-title>
          </v-row>
        </v-col>
        <v-col cols="9">
          <v-row>
            <v-col cols="7" class="mt-5">
              <v-text-field
                v-model="searchVal"
                label="Search"
                append-icon="mdi-magnify"
                @change="search"
                outlined
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="mt-6">
              <v-btn
                @click="changeCurrency"
                class="primary--text"
                elevation="0"
              >
                {{ currency.denom }}
                <v-icon color="primary">mdi-repeat</v-icon>
              </v-btn>
            </v-col>
            <v-col class="hidden-sm-and-down mt-4" cols="2">
              <!-- <v-app-bar-nav-icon @click="drawer = !drawer">
                <v-icon color="primary">mdi-account</v-icon>
              </v-app-bar-nav-icon>-->
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="colour.on"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    fab
                    x-small
                  >
                    <v-icon :color="colour.off">mdi-account</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-icon color="accent" @click="logout">mdi-logout</v-icon>
                    <v-list-item-title>Sign out</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-app-bar-nav-icon @click="basket = !basket">
                <v-badge color="green" :content="currency.denom + cartTotal"
                  ><v-icon color="primary">mdi-basket</v-icon>
                </v-badge>
              </v-app-bar-nav-icon>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed right temporary :width="450">
      <User />
    </v-navigation-drawer>
    <v-navigation-drawer v-model="basket" fixed right temporary :width="450">
      <Basket :checkout="checkout" @showCheckout="checkout = $event" />
    </v-navigation-drawer>
    <v-navigation-drawer v-model="checkout" fixed right temporary :width="450">
      <Checkout />
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
import Basket from "../components/basket";
import Checkout from "./checkout";
import User from "./user";

export default {
  name: "App",

  data: () => ({
    drawer: false,
    basket: false,
    checkout: false,
    searchVal: ""
  }),

  components: {
    Basket,
    User,
    Checkout
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    search() {
      this.$store.dispatch("search", this.searchVal);
    },
    changeCurrency() {
      this.$store.dispatch("chngCurrency");
    }
  },
  computed: {
    cartTotal() {
      let total = this.currency.value * this.$store.getters.total;
      return total;
    },
    currency() {
      return this.$store.getters.currency;
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    colour() {
      var colour = { on: "", off: "" };
      if (this.user.loggedIn) {
        colour.on = "primary";
        colour.off = "";
      } else {
        colour.off = "";
        colour.on = "primary";
      }
      return colour;
    }
  }
};
</script>
