<template>
  <v-app>
    <TopNav />
    <v-main class="mt-12">
      <v-container fluid class="pa-0">
        <Products />
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from "../components/footer";
import Products from "../sections/products";
import TopNav from "../sections/topNav";

export default {
  name: "Home",

  components: {
    Products,
    TopNav,
    Footer
  }
};
</script>
