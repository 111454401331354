<template>
  <v-layout row wrap class="mb-3" justify-space-around>
    <v-flex xs10
      ><Categories :categories="categories" @clicked="findProduct"
    /></v-flex>
    <v-flex xs10>
      <v-expansion-panels class="mt-4" multiple accordion :value="expanded">
        <v-expansion-panel
          class="mt-4"
          v-for="(category, index) in categories"
          :key="index"
        >
          <v-expansion-panel-header color="primary" class="white--text">
            {{ category }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col
                cols="12"
                md="3"
                sm="6"
                xs12
                v-for="product in findProduct(category)"
                :key="product.id"
              >
                <Product :product="product" />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-flex>
  </v-layout>
</template>

<script>
import Product from "../components/product";
import Categories from "../components/categories";

export default {
  name: "Products",

  components: {
    Product,
    Categories
  },

  beforeCreate: function() {
    this.$store.dispatch("getProducts");
  },

  data() {
    return {
      expanded: [0, 1, 2, 3, 4]
    };
  },

  methods: {
    findProduct(category) {
      return this.products.filter(product => product.category === category);
    }
  },

  computed: {
    products() {
      let products = this.$store.getters.products;
      let searchVal = this.$store.getters.searchVal;
      let filterVal = this.$store.getters.filterVal;
      if (searchVal) {
        products = products.filter(
          item => item.name.toLowerCase().search(searchVal.toLowerCase()) !== -1
        );
      }
      if (filterVal) {
        products = products.filter(product => product.category === filterVal);
      }
      return products;
    },
    categories() {
      var products = this.products;
      var order = ["Fruits", "Vegetables"];
      var categories = [];
      categories = products
        .map(item => item.category, 1)
        .filter((item, index, products) => products.indexOf(item) == index);
      order.forEach(item => {
        let idx = categories.indexOf(item);
        if (categories.length > 1) {
          categories.splice(idx, 1);
          categories.unshift(item);
        }
      });
      return categories;
    }
  }
};
</script>
