<template>
  <v-card>
    <v-row justify="center">
      <v-col cols="9" md="8"
        ><v-img :src="product.image" height="120" contain></v-img
      ></v-col>
      <v-col cols="3" md="4">
        <v-row>
          <v-col cols="10">
            <v-btn color="transparent" @click="change('minus')" fab x-small>
              <v-icon color="red">mdi-basket-unfill</v-icon>
            </v-btn>
          </v-col>
          <v-col class="py-2" cols="10">
            <v-btn color="transparent" @click="change('add')" fab x-small>
              <v-icon color="green darken-2">mdi-basket-fill</v-icon>
            </v-btn>
          </v-col>
          <v-col class="py-2" cols="10">
            <v-btn color="secondary" class="black--text" fab x-small>
              <v-card-text
                class="font-weight-bold"
                v-text="itemCount"
              ></v-card-text>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-card-text class="grey lighten-4">
      <v-container class="pa-0 fill-height">
        <v-row row wrap class="pa-0" justify="center">
          <v-col class="pb-1">
            <v-chip class="" color="primary" text-color="white">
              <v-avatar left>
                <v-icon>mdi-shopping</v-icon>
              </v-avatar>
              {{ product.name }}
            </v-chip>
          </v-col>
          <v-col class="pb-1">
            <v-chip
              class=""
              color="primary"
              text-color="white"
              @click="changeCurrency"
            >
              {{ price }} | {{ product.unit }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row class="pa-1"> {{ product.description }}</v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    show: false,
    carts: [],
    action: "",
    counter: 0,
    total: 0
  }),
  props: {
    product: Object
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.carts = this.$store.getters.cart;
    },
    changeCurrency() {
      this.$store.dispatch("chngCurrency");
    },
    change(act) {
      switch (act) {
        case "add":
          this.$store.dispatch("addItem", this.product);
          break;
        case "minus":
          this.$store.dispatch("removeItem", this.product);
          break;
      }
    }
  },
  computed: {
    cart() {
      return this.$store.getters.cart;
    },
    price() {
      let price = this.currency.value * this.product.price;
      return this.currency.denom + price.toFixed(2);
    },
    currency() {
      return this.$store.getters.currency;
    },
    cartItem() {
      return this.cart.find(item => item.id === this.product.id);
    },
    itemCount() {
      return this.cartItem ? this.cartItem.quantity : 0;
    }
  }
};
</script>
