<template>
  <v-row>
    <v-btn color="success" class="mr-4" @click="getOrder"> View Order </v-btn>
    <v-btn color="success" class="mr-4" @click="finish"> Finish </v-btn>
  </v-row>
</template>

<script>
import { db, fn } from "../firebase";
import { jsPDF } from "jspdf";
import img from "../assets/base64/image";
import "jspdf-autotable";

export default {
  name: "Invoice",

  data: () => ({
    finalOrder: "dddd"
  }),

  props: {
    next: Number
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    order() {
      return this.$store.getters.orderId.toString();
    },

    cartTotal() {
      let total = this.currency.value * this.$store.getters.total;
      return total;
    },
    zwlRate() {
      let currencies = this.$store.getters.currencies;
      let found = currencies.find(currency => currency.denom == "$ZWL");
      return found.value;
    }
  },

  methods: {
    bodyRows(cart) {
      var body = [];
      cart.forEach(item => {
        var itemTotal = item.price * item.quantity;
        body.push([
          item.name,
          item.quantity + " x " + item.unit,
          itemTotal.toFixed(2)
        ]);
      });
      return body;
    },
    printInvoice(order) {
      var cartBody = this.bodyRows(order.cart);
      var total = order.total;
      var doc = new jsPDF();
      var imgData = img;
      doc.setFillColor(73, 160, 16);
      doc.rect(0, 0, 210, 12, "FD");
      doc.setTextColor(255, 255, 255);
      doc.text("DELIVERY ORDER", 80, 8);
      doc.addImage(imgData, "PNG", 120, 20, 50, 25);
      doc.autoTable({
        tableWidth: 70,
        theme: "plain",
        head: [["Delivery Details"]],
        body: [
          [order.receipient],
          [order.destination],
          [order.phone],
          ["Due on: " + order.deliveryTime]
          // ...
        ]
      });
      doc.autoTable({
        tableWidth: 70,
        margin: 125,
        theme: "grid",
        head: [["item", "Detail"]],
        body: [
          ["Order #", order.order],
          ["Ordered By", order.user],
          ["Order Date", order.transactionDate]
        ],
        showHead: false,
        columnStyles: {
          item: {
            fillColor: [41, 128, 185],
            textColor: 255,
            fontStyle: "bold"
          }
        }
      });
      doc.autoTable({
        theme: "grid",
        headStyles: {
          fillColor: [73, 160, 16]
        },
        head: [["item Description", "Quantity", "Amount"]],
        body: cartBody,
        columnStyles: {
          item: {
            fillColor: [41, 128, 185],
            textColor: 200,
            fontStyle: "bold"
          }
        }
      });
      doc.autoTable({
        tableWidth: 40,
        margin: { left: 155 },
        headStyles: {
          fillColor: [73, 160, 16]
        },
        theme: "grid",
        head: [["", "Total Due"]],
        body: [
          ["USD$", total],
          ["ZWL$", total * this.zwlRate]
          // ...
        ]
      });
      doc.output("dataurlnewwindow");
      doc.save("order" + order.order);
    },
    getOrder() {
      var orders = db.collection("orders").doc(this.$store.getters.orderId);
      orders
        .get()
        .then(doc => {
          this.printInvoice(doc.data());
        })
        .catch(error => {
          console.log("Error getting documents: ", error);
          return error;
        });
    },
    sendMail(data) {
      const mailer = fn.httpsCallable("orderMailer");
      mailer(data)
        .then(res => {
          console.log(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    makeMail(file) {
      const mailOptions = {
        from: "orders@tuktuk.co.zw", // sender address
        to: "zivaicz@gmail.",
        subject: "order", // Subject line
        html: <p>test</p>, // plain text body
        attachments: { path: file }
      };
      this.sendMail(mailOptions);
    },
    finish() {
      this.$store.dispatch("auth/logout");
    }
  }
};
</script>
