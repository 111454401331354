<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="receipient"
      :counter="10"
      :rules="receipientRules"
      label="Receipient's name"
      required
    ></v-text-field>

    <v-text-field
      v-model="phone"
      :counter="10"
      :rules="phoneRules"
      label="Receipients Contant Number"
      required
    ></v-text-field>

    <v-select
      v-model="suburb"
      :items="suburbItems"
      error-messages="selectErrors"
      label="Suburb"
      required
    ></v-select>

    <v-text-field
      v-model="street"
      :rules="streetRules"
      label="Street No/Address"
      required
    ></v-text-field>

    <v-btn
      :loading="loader"
      :disabled="!valid"
      color="success"
      class="mr-4"
      @click="validate"
    >
      NEXT
    </v-btn>

    <v-snackbar :value="snackbar" :multi-line="true">
      {{ snackbarMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
export default {
  name: "Delivery",

  props: {
    firebase: Object,
    next: Number
  },

  data: () => ({
    nxt: null,
    valid: true,
    receipient: "",
    receipientRules: [
      v => !!v || "Username is required",
      v => (v && v.length <= 30) || "Username must be less than 10 characters"
    ],
    phone: "",
    phoneRules: [
      v => !!v || "Recipient's number is required",
      v => (v && v.length <= 30) || "Username must be less than 10 characters"
    ],
    street: "",
    streetRules: [
      v => !!v || "Street/Flat number and name is required",
      v => (v && v.length <= 30) || "Username must be less than 10 characters"
    ],
    suburb: null,
    suburbItems: [
      "Adylinn, Harare",
      "Alexandra Park, Harare",
      "Alexandra Park (New), Harare",
      "Amby, Harare",
      "Arcadia, Harare",
      "Ardbennie, Harare",
      "Ardbennie (New), Harare",
      "Arlingon, Harare",
      "Ashbrittle, Harare",
      "Ashdown Park, Harare",
      "Aspindale Park, Harare",
      "Athlone, Harare",
      "Avenues, Harare",
      "Avondale, Harare",
      "Avondale West, Harare",
      "Avonlea, Harare",
      "Ballantyne Park, Harare",
      "Belgravia, Harare",
      "Belvedere North, Harare",
      "Belvedere South, Harare",
      "Beverley, Harare",
      "Beverley West, Harare",
      "Bloomingdale, Harare",
      "Bluf Hill (New), Harare",
      "Bluff Hill, Harare",
      "Bluff Hill Park, Harare",
      "Borrowdale, Harare",
      "Borrowdale Brooke, Harare",
      "Borrowdale West, Harare",
      "Braeside, Harare",
      "Brooke Ridge, Harare",
      "Budiriro 1, Harare",
      "Budiriro 2, Harare",
      "Budiriro 3, Harare",
      "Budiriro 4, Harare",
      "Budiriro 5, Harare",
      "Carrick Creagh, Harare",
      "Chadcombe, Harare",
      "Chikurubi, Harare",
      "Chiremba Park, Harare",
      "Chisipitie, Harare",
      "Chizhanje, Harare",
      "City Centre, Harare",
      "Civic Centre, Harare",
      "Civic Centre, Harare",
      "Cold Comfort, Harare",
      "Colne Valley, Harare",
      "Colray, Harare",
      "Coronation Park, Harare",
      "Coronation Park, Harare",
      "Cotswold Hills, Harare",
      "Cranbourne Park, Harare",
      "Crowborough, Harare",
      "Crowborough North, Harare",
      "Dawn Hill, Harare",
      "Donnybrook, Harare",
      "Dzivaresekwa, Harare",
      "Eastlea North, Harare",
      "Eastlea South, Harare",
      "Emerald Hill, Harare",
      "Epworth, Harare",
      "Gevstein Park, Harare",
      "Glaudina, Harare",
      "Glen Lorne, Harare",
      "Glen Norah, Harare",
      "Glen View, Harare",
      "Glenwood, Harare",
      "Graniteside, Harare",
      "Green Grove , Harare",
      "Greencroft, Harare",
      "Greendale, Harare",
      "Greystone Park, Harare",
      "Grobbie Park, Harare",
      "Groombridge, Harare",
      "Gun Hill, Harare",
      "Haig Park, Harare",
      "Hatcliffe, Harare",
      "Hatfield, Harare",
      "Helensvale, Harare",
      "Highfield, Harare",
      "Highlands, Harare",
      "Hillside, Harare",
      "Hogerty Hill, Harare",
      "Hopley, Harare",
      "Houghton Park, Harare",
      "Induna, Harare",
      "Kambanje, Harare",
      "Kambuzuma, Harare",
      "Kensington, Harare",
      "Kopje, Harare",
      "Kutsaga, Harare",
      "Kuwadzana, Harare",
      "Kuwdzana Phase 3, Harare",
      "Letombo Park, Harare",
      "Lewisam, Harare",
      "Lichendale, Harare",
      "Lincoln Green, Harare",
      "Little Norfolk, Harare",
      "Lochinvar, Harare",
      "Logan Park, Harare",
      "Lorelei, Harare",
      "Luna, Harare",
      "Mabelreign, Harare",
      "Mabvuku, Harare",
      "Mainway Meadows, Harare",
      "Malvern, Harare",
      "Mandara, Harare",
      "Manidoda Park, Harare",
      "Manresa, Harare",
      "Marimba Park, Harare",
      "Marlborough, Harare",
      "Marlborough (New), Harare",
      "Mayfield Park, Harare",
      "Mbare, Harare",
      "Mbare, Harare",
      "Meyrick Park, Harare",
      "Midlands, Harare",
      "Milton Park, Harare",
      "Monovale, Harare",
      "Mount Hampden, Harare",
      "Mount Pleasant, Harare",
      "Msasa, Harare",
      "Msasa Park, Harare",
      "Mufakose, Harare",
      "Mukuvisi, Harare",
      "Mukuvisi Park, Harare",
      "New Ridgeview, Harare",
      "Newlands, Harare",
      "Nkwisi Park, Harare",
      "Northwood, Harare",
      "Old Forest, Harare",
      "Park Meadowlands, Harare",
      "Parktown, Harare",
      "Philadelphia, Harare",
      "Pomona, Harare",
      "Prospect, Harare",
      "Prospect Park, Harare",
      "Queensdale, Harare",
      "Queensdale, Harare",
      "Quinnington, Harare",
      "Rhodesville, Harare",
      "Ridgeview, Harare",
      "Rietfontein, Harare",
      "Ringley, Harare",
      "Rolf Valley, Harare",
      "Rugare, Harare",
      "Runniville, Harare",
      "Ryelands, Harare",
      "Sanganai Park, Harare",
      "Science Park, Harare",
      "Sentosa, Harare",
      "Shawasha Hills, Harare",
      "Sherwood Park, Harare",
      "Shortson, Harare",
      "Southerton, Harare",
      "St. Andrews Park, Harare",
      "St. Martins, Harare",
      "Strathaven, Harare",
      "Sunningdale, Harare",
      "Sunridge, Harare",
      "Sunrise, Harare",
      "Tafara, Harare",
      "The Grange, Harare",
      "Tynwald, Harare",
      "Tynwald North, Harare",
      "Tynwald South, Harare",
      "Umwinsidale, Harare",
      "Uplands, Harare",
      "Vainona, Harare",
      "Valencedene, Harare",
      "Ventersburg, Harare",
      "Warren Park, Harare",
      "Warren Park D, Harare",
      "Warren Park North, Harare",
      "Warren Park North, Harare",
      "Waterfalls, Harare",
      "Westwood, Harare",
      "Willowvale, Harare",
      "Wilmington Park, Harare",
      "Workington, Harare"
    ],
    city: "",
    cityItems: ["Harare", "Bulawayo", "Kariba"]
  }),

  methods: {
    validate() {
      this.$store.dispatch("setLoad");
      this.$store.dispatch("setShowMsg");
      if (this.$refs.form.validate()) {
        var destination = this.street + ", " + this.suburb;
        var data = {
          value: 2,
          data: {
            receipient: this.receipient,
            phone: this.phone,
            destination: destination
          }
        };
        this.$store.dispatch("setStep", data);
      }
    },
    notifications(msg) {
      this.snackbarText = msg;
      this.snackbar = !this.snackbar;
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  },

  computed: {
    loader() {
      return this.$store.getters.load;
    },
    snackbarMsg() {
      return this.$store.getters.msg;
    },
    snackbar() {
      return this.$store.getters.showMsg;
    }
  }
};
</script>
