<template>
  <v-form ref="form">
    <v-select
      v-model="frequency"
      :items="items"
      class="mt-1"
      label="Prefered delivery frequency"
      outlined
    ></v-select>
    <v-radio-group class="pt-0 mt-0" v-model="radios" mandatory>
      <v-sheet
        v-for="option in options"
        :key="option.price"
        color="white"
        class="mb-2"
        elevation="8"
        height="70"
        width="350"
        ><v-row class="font-weight-bold" no-gutters>
          <v-col cols="12">
            {{ option.title }} {{ option.price.toFixed(2) }}
          </v-col>
          <v-col cols="12">
            <v-radio :value="option">
              <template v-slot:label>
                {{ option.value }}
              </template>
            </v-radio>
          </v-col>
        </v-row>
      </v-sheet>
    </v-radio-group>
    <v-btn
      :loading="loader"
      :disabled="!valid"
      color="success"
      class="mr-4"
      @click="validate"
    >
      NEXT
    </v-btn>
    <v-btn text @click="prev"> PREVIOUS </v-btn>
  </v-form>
</template>

<script>
export default {
  name: "DeliveryOptions",

  data() {
    return {
      valid: true,
      items: ["Monthly", "Fortnightly", "Weekly", "Once-off"],
      options: [
        { class: "Free", title: "Next Free Delivery", value: "", price: 0.0 },
        { class: "Prime", title: "Next Day Delivery", value: "", price: 5.0 }
      ],
      frequency: "Once-off",
      radios: null
    };
  },

  props: {
    firebase: Object,
    next: Number
  },

  created() {
    this.options[0].value = this.nextDelivery;
    this.options[1].value = this.tomorrow;
  },

  methods: {
    prev() {
      this.$store.dispatch("back");
    },
    validate() {
      this.$store.dispatch("setLoad");
      this.$store.dispatch("setShowMsg");
      if (this.$refs.form.validate()) {
        var data = {
          value: this.next,
          data: {
            frequency: this.frequency,
            deliveryType: this.radios.class,
            deliveryTime: this.radios.value,
            charge: this.radios.price
          }
        };
        this.$store.dispatch("setStep", data);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    formatDate(date) {
      let days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ];
      let months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      var day = date.getDay();
      var dd = date.getDate();
      var mm = months[date.getMonth()];
      var yyyy = date.getFullYear();
      return days[day] + "," + dd + " " + mm + " " + yyyy;
    },
    addDay(days) {
      var now = new Date();
      return new Date(now.setDate(now.getDate() + days));
    }
  },

  computed: {
    loader() {
      return this.$store.getters.load;
    },
    tomorrow() {
      return this.formatDate(this.addDay(1));
    },
    nextDelivery() {
      let next = null;
      let week = [];
      for (var i = 1; i < 7; i++) {
        week.push(this.addDay(i));
      }
      switch (new Date().getDay()) {
        case 6:
        case 0:
        case 1:
        case 2:
          next = week.filter(item => item.getDay() === 3);
          return this.formatDate(next[0]);
        case 3:
        case 4:
        case 5:
          next = week.filter(item => item.getDay() === 6);
          return this.formatDate(next[0]);
        default:
          return 0;
      }
    }
  }
};
</script>
