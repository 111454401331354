<template>
  <v-sheet class="mx-auto" xs10 light>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-layout v-if="categories.length > 0" row justify-space-around>{{
      setTitle(1)
    }}</v-layout>
    <v-layout v-else row justify-space-around>{{ setTitle(2) }}</v-layout>
    <v-slide-group multiple show-arrows>
      <v-slide-item
        v-for="category in catList"
        :key="category.name"
        v-slot="{ active, toggle }"
      >
        <v-btn
          class="mx-2 secondary black--text"
          :input-value="active"
          depressed
          rounded
          @click="
            toggle;
            filter(category.name);
          "
        >
          {{ category.name
          }}<v-icon v-if="status" :name="category.name" color="red"
            >mdi-close</v-icon
          >
        </v-btn>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>

<script>
export default {
  data: () => ({
    model: null,
    title: "Loading products...",
    overlay: true,
    status: false
  }),
  props: {
    categories: Array
  },

  computed: {
    catList() {
      let categoryList = [];
      this.categories.forEach(category => {
        categoryList.push({ name: category, status: false });
      });
      return categoryList;
    },
    filteredCategories(cat) {
      return this.keys.filter(key => key !== cat);
    }
  },

  methods: {
    setTitle(x) {
      if (x == 1) {
        this.title = "Categories ";
        this.overlay = false;

        return this.title;
      } else {
        this.title = "Loading Products...";
        return this.title;
      }
    },
    filter(category) {
      this.status = !this.status;
      if (this.status) {
        this.$store.dispatch("filter", category);
      } else {
        category = "";
        this.$store.dispatch("filter", category);
      }
    }
  }
};
</script>
