<template>
  <v-card>
    <v-system-bar app color="primary" dark> </v-system-bar>
    <v-app-bar dark color="primary" app>
      TOTAL
      <v-btn color="primary" @click="changeCurrency">
        <v-icon>mdi-chevron-down</v-icon>
        {{ currency.denom }} {{ cartTotal }}
      </v-btn>

      <v-toolbar-title></v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        class="hidden-sm-and-down"
        outlined
        rounded
        small
        @click="showCheckout(checkout)"
      >
        CHECKOUT
      </v-btn>
      <v-btn
        class="hidden-md-and-up"
        outlined
        rounded
        small
        @click="toCheckout('Checkout')"
      >
        CHECKOUT
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-card-title>My Basket</v-card-title>
      <v-container>
        <v-img
          v-if="getCart.length == 0"
          :src="require('@/assets/emptyCart.svg')"
          max-width="350"
        ></v-img>
        <v-row class="ma-1" dense>
          <v-col v-for="product in getCart" :key="product.id" cols="12">
            <v-card class="secondary" light>
              <v-card-title class="px-1 py-0">
                {{ product.name }}
                <v-spacer></v-spacer>
                <span class="float-right" @click="addItem(product)">
                  <v-btn fab icon height="40px" right>
                    <v-icon color="green darken-2">mdi-arrow-up</v-icon>
                  </v-btn>
                </span>
                <span class="float-right" @click="reduceItem(product)">
                  <v-btn class="ml-2" fab icon height="40px" right>
                    <v-icon color="red darken-2">mdi-arrow-down</v-icon>
                  </v-btn>
                </span>
                <span class="float-right" @click="removeItem(product)">
                  <v-btn class="ml-2" fab icon height="40px" right>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </span>
              </v-card-title>
              <v-card-text class="px-1 py-0">
                Quantity: {{ product.quantity }} x {{ product.unit }}(s) |
                Total:{{ currency.denom }}
                {{ productTotal(product) }}
              </v-card-text>
              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-snackbar :value="snackbar" :multi-line="true">
          {{ snackbarMsg }}
          <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>
    </v-main>
  </v-card>
</template>

<script>
export default {
  name: "Basket",

  props: {
    checkout: Boolean
  },

  data: () => ({
    total: 0
  }),
  computed: {
    getCart() {
      return this.$store.getters.cart;
    },
    cartTotal() {
      let total = this.currency.value * this.$store.getters.total;
      return total;
    },
    currency() {
      return this.$store.getters.currency;
    },
    snackbarMsg() {
      return this.$store.getters.msg;
    },
    snackbar() {
      return this.$store.getters.showMsg;
    }
  },
  methods: {
    productTotal(prd) {
      var total = prd.price * prd.quantity * this.currency.value;
      return total.toFixed(2);
    },
    toCheckout(path) {
      var minOrder = this.currency.value * 10;
      if (this.cartTotal >= minOrder) {
        this.$router.push({ name: path });
      } else {
        this.$store.dispatch(
          "setMsg",
          `Can not checkout minimum order is ` + minOrder
        );
      }
    },
    showCheckout(checkout) {
      var minOrder = this.currency.value * 10;
      if (this.cartTotal >= minOrder) {
        let check = !checkout;
        this.$emit("showCheckout", check);
      } else {
        this.$store.dispatch(
          "setMsg",
          `Can not proceed minimum order is ` + minOrder
        );
      }
    },
    changeCurrency() {
      this.$store.dispatch("chngCurrency");
    },
    addItem(item) {
      this.$store.dispatch("addItem", item);
    },
    reduceItem(item) {
      this.$store.dispatch("removeItem", item);
    },
    removeItem(item) {
      item.quantity = 0;
      this.$store.dispatch("removeItem", item);
    }
  }
};
</script>
