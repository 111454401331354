<template>
  <v-card>
    <v-system-bar app color="primary" dark> </v-system-bar>
    <v-app-bar dark color="primary" app> CHECKING OUT </v-app-bar>
    <v-main>
      <v-stepper v-if="show.flag" v-model="step" vertical>
        <v-stepper-step
          :color="step > 1 ? 'primary' : 'success'"
          :complete="step > 1"
          step="1"
        >
          Login
          <small>You must be logged in to complete checkout</small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <Login :next="step" />
        </v-stepper-content>

        <v-stepper-step
          :color="step > 2 ? 'primary' : 'success'"
          :complete="step > 2"
          step="2"
        >
          Delivery Details
        </v-stepper-step>

        <v-stepper-content step="2">
          <Delivery :next="step" />
        </v-stepper-content>

        <v-stepper-step
          :color="step > 3 ? 'primary' : 'success'"
          :complete="step > 3"
          step="3"
        >
          Select delivery time
        </v-stepper-step>

        <v-stepper-content step="3">
          <DeliveryOptions :next="step" />
        </v-stepper-content>

        <v-stepper-step
          :color="step > 4 ? 'primary' : 'success'"
          :complete="step > 4"
          step="4"
        >
          Order Summary
        </v-stepper-step>

        <v-stepper-content step="4">
          <Order :next="step" />
        </v-stepper-content>

        <v-stepper-step step="5" :color="step == 5 ? 'primary' : 'success'">
          Order Completed
        </v-stepper-step>
        <v-stepper-content step="5">
          <Invoice :next="step" />
        </v-stepper-content>
      </v-stepper>
      <v-card-text v-else>
        Minimum order is {{ show.min }}, add more items for you to checkout.
      </v-card-text>
    </v-main>
  </v-card>
</template>

<script>
import Login from "../components/login";
import Delivery from "../components/delivery";
import DeliveryOptions from "../components/deliveryOptions";
import Order from "../components/orderSummary";
import Invoice from "../components/invoice";
import { db, fb } from "../firebase";

export default {
  name: "Checkout",

  components: {
    Login,
    Delivery,
    DeliveryOptions,
    Order,
    Invoice
  },

  data() {
    return {
      fb: fb
    };
  },

  computed: {
    show() {
      var minOrder = this.currency.value * 10;
      var show = { flag: false, min: minOrder };
      if (this.cartTotal >= minOrder) {
        show.flag = true;
      }
      return show;
    },
    step() {
      return this.$store.getters.step;
    },
    user() {
      return this.$store.getters.user;
    },
    getOrder() {
      var order = db.collection("orders").doc(this.$store.getters.orderId);
      return order;
    },
    getCart() {
      return this.$store.getters.cart;
    },
    cartTotal() {
      let total = this.currency.value * this.$store.getters.total;
      return total;
    },
    currency() {
      return this.$store.getters.currency;
    }
  }
};
</script>
