<template>
  <v-form ref="form">
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left font-weight-bold">Product</th>
            <th class="text-left font-weight-bold">Quantity</th>
            <th class="text-left font-weight-bold">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in getCart" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ item.quantity }} {{ item.unit }}(s)</td>
            <td>
              {{ currency.denom }}
              {{ itemTotal(item) }}
            </td>
          </tr>
          <tr class="headline">
            <td class="font-weight-bold">Total</td>
            <td></td>
            <td class="font-weight-bold">
              {{ currency.denom }} {{ cartTotal }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-btn
      :loading="loader"
      :disabled="!valid"
      color="success"
      class="mr-4"
      @click="validate"
    >
      CONFIRM ORDER
    </v-btn>
    <v-btn text @click="prev"> PREVIOUS </v-btn>
  </v-form>
</template>

<script>
export default {
  name: "Order",

  data() {
    return {
      valid: true,
      radios: "24"
    };
  },

  props: {
    next: Number
  },

  computed: {
    loader() {
      return this.$store.getters.load;
    },
    user() {
      return this.$store.getters.user;
    },
    getCart() {
      return this.$store.getters.cart;
    },
    cartTotal() {
      let total = this.currency.value * this.$store.getters.total;
      return total;
    },
    currency() {
      return this.$store.getters.currency;
    }
  },

  methods: {
    prev() {
      this.$store.dispatch("back");
    },
    itemTotal(item) {
      var total = item.quantity * item.price * this.currency.value;
      return total.toFixed(2);
    },
    validate() {
      this.$store.dispatch("setLoad");
      this.$store.dispatch("setShowMsg");
      if (this.$refs.form.validate()) {
        var data = {
          value: this.next,
          data: {
            confirmed: true
          }
        };
        this.$store.dispatch("setStep", data);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>
